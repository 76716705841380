import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { colors, device } from '../GlobalStyles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import mobile from '../../../images/svgs/mobile.svg';
import marker from '../../../images/svgs/marker.svg';
import instagram from '../../../images/svgs/instagram.svg';
import facebook from '../../../images/svgs/facebook.svg';
import logo from '../../../images/vytal-logo.png';
import { Button } from '../StyledComponents';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const StyledFooter = styled.footer`
    a {
        color: ${colors.white};
    }
    color: ${colors.white};
    font-family: 'Bebas Neue', cursive;
    padding-top: 2em;
    text-align: center;
    z-index: 300;
    .black-bg {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(50px);
        padding: 1em 0em 4em;
    }
    .line {
        display: none;
    }
    .markers {
        p {
            font-family: 'Bebas Neue', cursive;
            align-items: center;
            display: flex;
            justify-content: center;
            img {
                margin-right: 0.5em;
            }
        }
    }
    .social {
        display: flex;
        justify-content: center;
        cursor: pointer;
        img {
            margin: 1em 2em 3.5em;
        }
    }
    li {
        margin: 3em 0em;
        cursor: pointer;
    }
    .filter {
        z-index: -1;
        background: rgba(0, 0, 0, 0.2);
    }
    h2 {
        color: ${colors.white};
        margin: 1em 0em 2em;
    }
    p {
        font-family: 'Bebas Neue', cursive;
        margin-bottom: 2em;
    }
    .logo {
        width: 186.39px;
        height: 128px;
        margin: 0 auto;
    }
    button {
        margin: 0em 1em 3.5em;
    }
    @media ${device.laptop} {
        .line {
            display: block;
        }
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0em 2em;
                list-style-type: none;
                padding: 1.5em 0em 4em;
            }
        }
        .markers {
            display: flex;
        }
        .line {
            width: 34%;
            background: white;
            height: 1px;
            position: relative;
            bottom: 1em;
        }
        p {
            margin-left: 1em;
        }
        .desktop-flex {
            justify-content: space-around;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .social {
            align-items: center;
        }
    }
    @media ${device.desktop} {
        .desktop-flex {
            max-width: 1550px;
        }
    }
`;

export const Footer: React.FC = () => {
    const { footerBackground } = useStaticQuery(graphql`
        query {
            footerBackground: file(relativePath: { eq: "city.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                }
            }
        }
    `);

    const image = getImage(footerBackground);
    const bgImage = convertToBgImage(image);

    return (
        <BackgroundImage
            Tag="section"
            css={css`
                z-index: 20;
                background-size: cover;
                @media ${device.tablet} {
                    background-size: contain;
                }
            `}
            {...bgImage}
            preserveStackingContext
        >
            <StyledFooter>
                <div className="filter">
                    <img className="logo" src={logo} alt="company logo" />
                    <h2>SCHEDULE A CALL WITH US</h2>
                    <p>FIND OUT HOW WE CAN BEST HELP YOU</p>
                    <Link to="/contact">
                        <Button color={colors.white}>CONTACT US</Button>
                    </Link>
                    <Link to="/book-appointment">
                        <Button color={colors.white}>BOOK AN APPOINTMENT</Button>
                    </Link>
                    <div className="black-bg">
                        <ul>
                            <Link to="/contact">
                                {' '}
                                <li>CONTACT US</li>
                            </Link>
                            <Link to="/about-us">
                                <li>ABOUT US</li>
                            </Link>
                            <Link to="/surgical">
                                {' '}
                                <li>SERVICES</li>
                            </Link>
                            <a href="https://www.myaestheticspro.com/clientportal/dsp_loginportal.cfm?AE8224C6C9C47DBB820C4BC6F5B1B12A">
                                <li>CLIENT PORTAL</li>
                            </a>
                            <li>SITEMAP</li>
                        </ul>
                        <div className="desktop-flex">
                            <div className="markers">
                                <a
                                    style={{ color: 'white' }}
                                    target="_blank"
                                    href="tel:8185785125"
                                    rel="noreferrer"
                                >
                                    <p>
                                        <img src={mobile} alt="mobile phone icon" /> (818) 578 5125
                                    </p>
                                </a>
                                <a
                                    style={{ color: 'white' }}
                                    target="_blank"
                                    href="https://www.google.com/maps/place/19228+Ventura+Blvd+a,+Tarzana,+CA+91356,+USA/@52.676695,-7.8065301,14z/data=!3m1!4b1!4m2!3m1!1s0x80c299148b367eb7:0x69910eea314ee511"
                                    rel="noreferrer"
                                >
                                    <p>
                                        <img src={marker} alt="map marker icon" /> 19228 Ventura
                                        Blvd, Suite A,Tarzana, CA 91356
                                    </p>
                                </a>
                            </div>
                            <div className="line"></div>
                            <div className="social">
                                <a
                                    href="https://www.instagram.com/vytalcenter/?hl=en"
                                    target="__blank"
                                >
                                    <img src={instagram} alt="instagram logo" />
                                </a>
                                <a href="https://www.facebook.com/vytalcenter/" target="__blank">
                                    <img src={facebook} alt="facebook logo" />
                                </a>
                            </div>
                        </div>
                        <span>VYTAL SURGICAL INSTITUTE. ALL RIGHT RESERVED 2022</span>
                    </div>
                </div>
            </StyledFooter>
        </BackgroundImage>
    );
};
