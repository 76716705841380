export const navLinks = [
    {
        id: 0,
        name: 'ABOUT US',
        url: '/about-us',
    },
    {
        id: 1,
        name: 'OUR DOCTORS',
        url: '/our-doctors',
    },
    {
        id: 2,
        name: 'PATIENT RESOURCES',
        url: '/patient-resources',
    },
    {
        id: 3,
        name: 'SERVICES',
        url: '/surgical',
        dropdown: [
            { id: 0, name: 'BREAST IMPLANTS', url: '/aesthetics/breast-implants/' },
            { id: 1, name: 'BREAST LIFT', url: '/aesthetics/breast-lift/' },
            { id: 2, name: 'ERECTILE DYSFUNCTION', url: '/surgical/erectile-dysfunction/' },
            { id: 3, name: 'GASTROENTEROLOGY', url: '/surgical/gastroenterology-surgery/' },
            {
                id: 4,
                name: 'GYNECOLOGY',
                url: '/surgical/gynecology/',
            },
            { id: 5, name: 'NEUROSURGERY', url: '/surgical/neurosurgery/' },
            { id: 6, name: 'ORTHOPEDIC', url: '/surgical/orthopedic/' },
            {
                id: 7,
                name: 'PAIN MANAGEMENT',
                url: '/surgical/pain-management/',
            },
            { id: 8, name: 'PLASTIC SURGERY', url: '/surgical/plastic-reconstructive' },
            { id: 9, name: 'PODIATRIC', url: '/surgical/podiatric/' },
            { id: 10, name: 'CELLFINA', url: '/aesthetics/cellfina/' },
            { id: 11, name: 'ENT', url: '/surgical/ear-nose-and-throat' },
            { id: 12, name: 'HAIR RESTORATION', url: '/aesthetics/neograft-hair-restoration/' },
        ],
    },
    {
        id: 4,
        name: 'CLIENT PORTAL',
        url: 'https://www.myaestheticspro.com/clientportal/dsp_loginportal.cfm?AE8224C6C9C47DBB820C4BC6F5B1B12A',
    },
    {
        id: 5,
        name: 'CONTACT US',
        url: '/contact',
    },
];
